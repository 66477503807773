<template>
  <v-btn
    slot="activator"
    icon
    :disabled="disabled"
    :width="width"
    class="ma-0"
    @click="showImageDialog"
  >
    <v-icon>mdi-publish</v-icon>
  </v-btn>
</template>
<script>
import upload from "./uploadImage";

export default {
  props: ["resource", "id", "disabled", "width"],
  data() {
    return {
      menu: false,
      images: [],
      uploadImagesLoading: false,
    };
  },
  methods: {
    showImageDialog() {
      upload().then(images => {
        this.images = this.images.concat(images);
        this.$emit("upload", images);
        this.images = [];
      });
    },
  },
};
</script>
