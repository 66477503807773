<template>
  <v-layout>
    <app-loader
      title="Загрузка данных Новости"
      :loading="!pageLoaded"
    />
    <v-layout
      v-show="pageLoaded"
      v-if="!example"
      class="no-print"
      column
    >
      <v-card
        tile
        class="mt-0 mb-1 ml-0 pa-2 elevation-1"
        max-width
      >
        <v-layout align-center>
          <h2 class="px-2 title">
            {{ isNew ? `Создать ${entity}` : `Редактировать ${entity}` }}
          </h2>
        </v-layout>
      </v-card>
      <v-card
        tile
        class="mt-0 mb-1 ml-0 pa-2 elevation-1"
      >
        <v-card-text>
          <v-form>
            <v-layout column>
              <v-flex
                cols="12"
                md="4"
              >
                <v-text-field
                  v-model="title"
                  :rules="[rules.required, rules.counter]"
                  label="Наименование*"
                  required
                  outlined
                  dense
                  counter
                  maxlength="90"
                />
              </v-flex>

              <v-flex
                cols="12"
                md="4"
                class="mb-4"
              >
                <v-btn
                  @click="example = true"
                >
                  Показать на сайте
                </v-btn>
              </v-flex>

              <v-flex
                cols="12"
                md="4"
                class="mb-4"
              >
                <ImageUploader @upload="add" />
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      icon
                      :disabled="disableRemoveBtn"
                      class="ml-3"
                      @click="posterPath ? posterPath = '' : images = ''; imagesToSave = ''"
                      v-on="on"
                    >
                      <v-icon>
                        mdi-delete
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Удалить основное изображение</span>
                </v-tooltip>
                <v-img
                  v-if="posterPath"
                  :src="`${$url}${posterPath}`"
                  width="70%"
                />
                <div v-if="images.length >= 1">
                  <div
                    v-for="(image, idx) in images"
                    :key="idx"
                  >
                    <img
                      :src="image"
                      class="preview"
                      style="width: 70%"
                    >
                  </div>
                </div>
              </v-flex>

              <v-flex
                cols="12"
                md="4"
              >
                <h4>Краткое содержание статьи</h4>
                <ckeditor v-model="summary" />
              </v-flex>

              <v-flex
                cols="12"
                md="4"
              >
                <h4>Описание</h4>
                <ckeditor v-model="value" />
              </v-flex>
            </v-layout>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-flex
            cols="12"
            md="4"
          >
            <v-btn
              small
              text
              color="primary"
              @click="save(false)"
            >
              Сохранить
            </v-btn>
            <v-btn
              small
              text
              color="primary"
              @click="save(true)"
            >
              Сохранить и опубликовать
            </v-btn>
            <v-btn
              small
              text
              @click="$router.go(-1)"
            >
              Отмена
            </v-btn>
          </v-flex>
        </v-card-actions>
      </v-card>
    </v-layout>
    <v-container
      v-show="pageLoaded"
      v-else
      class="d-flex justify-center"
    >
      <v-card
        class="mt-0 mb-1 ml-0 pa-2 elevation-1"
        :style="{
          height: `${blockHeight + 'px'}`,
          'overflow-y': 'auto'
        }"
      >
        <v-card-title class="d-flex flex-column align-start">
          <h1
            class="h3 mb-2"
          >
            {{ title }}
          </h1>
          <span
            class="body-2"
          > {{ createdAt | date }} </span>
          <span
            class="body-2"
            v-html="summary"
          />
        </v-card-title>

        <v-card-text>
          <v-row class="d-flex justify-center">
            <v-img
              v-if="posterPath"
              :src="`${$url}${posterPath}`"
              style="width: 100%"
            />
            <div
              v-for="(image, idx) in images"
              v-else
              :key="idx"
            >
              <v-img
                :src="image"
                class="preview"
                style="width: 70%"
              />
            </div>
          </v-row>
          <v-row>
            <span
              class="body-1"
              v-html="value"
            />
          </v-row>
        </v-card-text>


        <v-card-actions
          class="d-flex justify-center"
        >
          <v-btn
            color="primary"
            @click="example = false; exampleList = true"
          >
            Вернуться к редактированию
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-container>
  </v-layout>
</template>

<script>
import * as postsApi from "../../../../backend/postsApi";
import ImageUploader from "../../../../components/images/ImageUploader";

export default {
  components: {
    ImageUploader,
  },
  props: ["id", "resource", "entity"],
  data() {
    return {
      loading: false,
      title: "",
      value: "",
      summary: "",
      images: [],
      imagesToSave: [],
      posterPath: "",
      dataRestored: false,
      width: null,
      example: false,
      exampleList: true,
      rules: {
        required: value => !!value || "Поле должно быть заполнено",
        counter: value => value.length <= 90 || "Не более 90 символов",
      },
    };
  },
  computed: {
    isNew() {
      return +this.id ? false : true;
    },
    blockHeight() {
      return window.innerHeight - 220;
    },
    pageLoaded() {
      return this.isNew ? true : !!this.dataRestored;
    },
    createdAt() {
      return this.moment();
    },
    disableRemoveBtn() {
      if (this.posterPath) {
        return false;
      } else {
        if (this.images.length >= 1) {
          return false;
        }
        return true;
      }
    },
    valid() {
      if (this.resource === "posts/news") {
        return !!this.title && !!this.summary && !!this.value;
      }
      return !!this.title && this.value;
    },
  },
  created() {
    if (!this.isNew) {
      this.getPost();
    }

  },
  methods: {
    async getPost() {
      this.loading = true;
      const res = await postsApi.getPost(this.resource, this.id);
      this.title = res.title;
      this.value = res.value;
      this.posterPath = res.posterPath;
      this.summary = res.summary;
      this.loading = false;
      this.dataRestored = true;
    },
    add(image) {
      this.posterPath = null;
      this.images = image.images;
      this.imagesToSave = image.result;
    },
    async save(isPublished) {
      if (!this.valid) {
        this.showError("Невозможно сохранить пустой пост");
        return;
      }

      if (!!this.summary && this.summary.length > 300) {
        this.showError("Краткое описание должно быть не более 300 символов");
        return;
      }

      const post = {
        title: this.title,
        value: this.value,
        summary: this.summary,
        isPublished,
      };

      try {
        this.isLoading = true;
        if (this.isNew) {
          const res = await postsApi.createPost(this.resource, post);
          this.uploadImage(res);
          this.getPost(res);
        } else {
          await postsApi.updatePost(this.id, post);
          this.uploadImage(this.id);
          this.getPost(this.id);
        }
      } catch (err) {
        this.showError(err);
      } finally {
        this.isLoading = false;
        this.$router.go(-1);
      }
    },
    async uploadImage(id) {
      if (this.imagesToSave && this.imagesToSave.length >= 1) {
        await postsApi.uploadImage(id, this.imagesToSave[0]);
      }
    },
  },
};
</script>
