import http from "./http.js";

import moment from "moment";
moment.locale("uk");

async function getItems(resource) {
  const response = await http.get(resource);
  return {
    totalItems: response.data.totalItems,
    items: response.data.result,
  };
}

async function createPost(resource, post) {
  const response = await http.post(resource, post);
  return response.data;
}

async function getPost(resource, id) {
  const response = await http.get(`${resource}/${id}`);
  return response.data;
}

async function uploadImage(id, img) {
  const response = await http.post(`posts/${id}/poster`, img);
  return response.data;
}

async function updatePost(id, post) {
  const response = await http.put(`posts/${id}`, post);
  return response.data;
}

async function removePost(id) {
  await http.delete(`posts/${id}`);
}

async function publishPost(id) {
  await http.patch(`posts/${id}/published`, {
    value: true,
  });
}


export {
  getItems,
  createPost,
  getPost,
  uploadImage,
  updatePost,
  removePost,
  publishPost,
};