<template>
  <Post
    :id="id"
    :resource="resource"
    entity="новость"
  />
</template>

<script>
import Post from "../components/Post.vue";

export default {
  components: {
    Post,
  },
  props: ["id"],
  data() {
    return {
      resource: "posts/news",
    };
  },
};
</script>
