const upload = function() {
  return new Promise(resolve => {
    const input = document.createElement("input");
    input.type = "file";
    document.body.appendChild(input);
    input.click();

    input.onchange = ev => {
      const result = [];
      const files = ev.target.files;
      for (let i = 0; i < files.length; i++) {
        const reader = new FileReader();
        reader.onload = e => {
          const arrayBuffer = e.target.result;
          const array = Array.from(new Uint8Array(arrayBuffer));
          result.push({
            originName: files[i].name,
            data: array,
          });
        };
        reader.readAsArrayBuffer(files[i]);
      }

      const images = [];
      const fileList = ev.target.files;
      fileList.forEach(f => {
        if (!f.type.match("image.*")) {
          return;
        }
        const reader = new FileReader();
        reader.onload = function(e) {
          images.push(e.target.result);
        };
        reader.readAsDataURL(f);
      });
      resolve({
        images,
        result,
      });
    };
  });
};

export default upload;
